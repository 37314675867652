button img {
    transition: transform .25s;
}

button:hover > img {
    transform: scale(1.2);
}

button:focus {
    outline: none;
}

input {
    font-family: 'Lexend Deca', sans-serif;
}

    input::placeholder {
        font-family: 'Lexend Deca', sans-serif;
    }

    input:focus {
        outline: none;
    }


.searchbox {
    height: 60px;
    max-width: 100%;
    background: white;
    border-radius: 10px;
    margin: 0 auto;
    padding: 10px;
    top: 50px;
    display: flex;
}

    .searchbox > .search {
        border: none;
        width: 100%;
    }

    .searchbox > .btn-search {
        background: transparent;
        border: none;
        cursor: pointer;
    }

.mb-1h {
    margin-bottom: 100px;
}