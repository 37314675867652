a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.sticky-header {
    position: sticky;
    background: white;
    top: 0;
    z-index: 200;
}