.discover-cat {
    transition: .15s all ease;
    display: inline-block;
    padding: .8rem 1.25rem;
    border: 1px solid #e8e8e8;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
    border-radius: 6px;
    margin-right: .25rem;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    list-style: none;
    white-space: nowrap;
    color: #9e9e9e;
    text-transform: capitalize;
}

.discover-cat:hover {
    background: #e8e8e8;
}